import * as React from "react"
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'

const ContactForm = () => (
    <div className="bg-gray-50 py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
      <div className="relative max-w-xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Get in touch</h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            We love to talk about horses: horse shows, horse riding, horse gear, and all things horse!
          </p>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            We can&apos;t wait to hear from you.
          </p>
        </div>
        <div className="mt-12">
          <form action="https://api-mkt.horseofcourseapp.com/contact-us" method="POST" className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
            <div className="sm:col-span-2">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="name"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-cyan-400 focus:border-cyan-400 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="py-3 px-4 block w-full shadow-sm focus:ring-cyan-400 focus:border-cyan-400 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Message
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="py-3 px-4 block w-full shadow-sm focus:ring-cyan-400 focus:border-cyan-400 border border-gray-300 rounded-md"
                  defaultValue={''}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <button
                type="submit"
                className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
              >
                Let's chat
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
)

export default ContactForm
