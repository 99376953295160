import * as React from "react"
import Layout from '../components/layout'
import ContactForm from '../components/contact-form'

const ContactPage = () => (
    <Layout>
      <ContactForm />
    </Layout>
)

export default ContactPage
